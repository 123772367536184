<!-- eslint-disable no-unused-vars -->
<template>
    <div class="carrinho-body" style=" ">
        <!-- ---------------------------------------------- caso esteja no loading --------------------------------------------------------  -->
        <div v-if="loading" class="loading">
            <img src="../assets/loading.gif" alt="" style="height: 45px" /> 
        </div>

        <div v-if="!loading" style="height: 90vh; overflow-y: scroll;">


            <div style="height: 30px;">
                <TituloDePaginaView  :titulo="'CARRINHO'" />
            </div>

            <div v-if="habilitarQrcodeScanner">
                <CameraScanner @onBarcodeLido="onBarcodeLido"/>
 
            </div>

            <!-- ---------------------------------------------- itens do carrinho (caso a camera nao esteja aberta) --------------------------------------------------------  -->
            <div v-if="!habilitarQrcodeScanner" style=" background-color: transparent ; height: fit-content; margin-bottom: 200px; ">
                
                <lista-itens-carrinho   
                    :carrinho="carrinho" 
                    :semItensNoCarrinho="!podeEnviar"
                    :deletarItem="deletarItem"
                    :deletarItemCombo="deletarItemCombo"
                />
            </div>
            
        </div>



        <!-- ---------------------------------------------------------------------------------- bottom bar ------------------------------------------------------------------  -->

    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import { mapGetters } from "vuex";
// eslint-disable-next-line no-unused-vars
import { rotaDeProducao } from "../api/rotas";
import Swal from "sweetalert2";
import { apiSincronizacao } from "@/api/ApiSincronizacao";
import { setarCores } from '@/utils/SetarCores';
// import CardItemCarrinho from '@/components/Carrinho/CardItemCarrinho.vue';
// import { controlePedirNumeroCartao } from '@/components/Controle/pedirNumeroCartao';
import { CONSOLOGAR } from '@/components/Controle/debug';
// eslint-disable-next-line no-unused-vars
import { apiBuscarStatusMesa } from '@/api/ApiPegarMesa';
import ListaItensCarrinho from "../components/Carrinho/ListaItensCarrinho.vue"
// import BottomBarCarrinho from "../components/Carrinho/BottomBarCarrinho.vue"
import PedidoModel from "@/model/pedidoModel";
import TituloDePaginaView from "../components/Base/TituloDePagina.vue"
import { controleConfirmarNumeroCartao } from '@/components/Controle/pedirNumeroCartao';
import { apiChamarGarcom } from '@/api/ApiChamarGarcon';
import CameraScanner from "@/utils/CameraScanner.vue";
// import { CONSOLOGAR } from '@/components/Controle/debug';

/**
 * Aqui estao os itens adicionados ao carrinho.
 *
 * @vue-data {Array} [precoTotalItem=[]] array de cada item com objs do id do item e o preco final
 * @vue-data {Double} [precoFinal=0] entra no array do precoTotalItem e é a soma dos itens de um combo
 * @vue-data {Double} [precoTotalFinal=0]
 * @vue-data {Boolean} [disabled=false]
 * @vue-data {Boolean} [podeEnviar=true] aponta se o botao enviar do carrinho vai estar enabled ou disabled
 * @vue-data {Boolean} [loading=false] vai apontar se deve mostrar o gif de loading ou nao
 * @vue-data {Boolean} [animarTextoDesabilidado=false] anima o texto para mostrar que o botao enviar esta desabilitado
 *
 * @vue-computed {Double} getFinalTotal retorna o preco total de todos os itens do carrinho
 *
 * @vue-watch getFinalTotal inicia o getFinalTotal no computed assim que a tela renderiza
 */
export default {
    name: "CarrinhoView",

    data() {
        return {
            precoTotalItem: [],
            precoFinal: 0,
            precoTotalFinal: 0,
            disabled: false,
            podeEnviar: true,
            loading: false,
            animarTextoDesabilidado: false,
            numeroComanda: null,
            //
            clicouEnviar: this.$store.state.onClickEnviarPedido,
            habilitarQrcodeScanner: false,
        }
    },

    computed: {
        ...mapGetters({
            carrinho: "getItensCarrinho",
        }),

        getFinalTotal: function () {
            return this.calcularValorTotalFinal().toFixed(2).replace(".", ",").replace(".", ",");
        },
    },

    created() {
        this.calcularPrecosItens();
        this.setarPodeEnviar();
        setarCores(this.$store.state.cor1, this.$store.state.cor2);
    },

    components: {
        ListaItensCarrinho,TituloDePaginaView,
        CameraScanner,
    },

    watch: {
        /** calcula o total final assim que a tela renderiza */
        getFinalTotal() {
            if (this.getFinalTotal == 0.0) {
                this.podeEnviar = false;
            }
        },

        /** vamos ouvir se a var {onClickEnviarPedido} mudou. Se ela mudou, eh pq o usuario clicou em enviar pedido */
        '$store.state.onClickEnviarPedido': function() {
            this.onClickConfirmar()
        },

    },

    methods: {

        limparCarrinhoDebug() {
            this.$store.commit('SET_LIMPAR_ITENS_CARRINHO')
        },

        calcularValorTotalFinal() {
            let finalPrecoTotal = 0.0;
            for (var i = 0; i < this.carrinho.length; i++) {
                finalPrecoTotal = finalPrecoTotal + parseFloat(this.carrinho[i].preco) * this.carrinho[i].quantidade;
            }
            return finalPrecoTotal;
        },

        /**
         * vai alterar a var podeEnviar para true ou false dependendo do getFinalTotal
         * @see podeEnviar
         * @see getFinalTotal
         */
        setarPodeEnviar() {
            if (this.carrinho == null || this.carrinho.length == 0) {
                this.podeEnviar = false;
            } else {
                this.podeEnviar = true;
            }
        },

        /** deleta item do carrinho e verificar se o carrinho esta vazio */
        deletarItem(item) {
            this.$store.dispatch("setDeletarItemCarrinho", item);
            this.disabled = true;
            setTimeout(() => {
                this.disabled = false;
            }, 1500);
            this.setarPodeEnviar();
        },

        /** deletar item do combo tem que deletar o combo todo  */ 
        deletarItemCombo(combo) {

            Swal.fire({

                title: "EXCLUIR COMBO", 
                text: "O item selecionado foi lançado dentro de um combo, deseja excluir todos os itens do mesmo",
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: "NÃO",

                
            }).then((result) => {
                // clicou em sim
                if (result.isConfirmed) { 
                    this.deletarItem(combo)
                }
                // clicou em nao = result.isDismissed
            })

        },

        /** calcula o preco dos itens */
        calcularPrecosItens() {
            for (var i = 0; i < this.carrinho.length; i++) {
                let preco = 0;
                let preco2 = 0;
                let preco3 = 0;
                preco = preco + parseFloat(this.carrinho[i].preco);

                // so calcular as etapas caso o item tenha etapas ( etapas != undefined )
                if (this.carrinho[i].etapas != undefined) {
                    for (
                        var ii = 0;
                        ii < this.carrinho[i].etapas.length;
                        ii++
                    ) {
                        preco2 =
                            preco2 +
                            parseFloat(this.carrinho[i].etapas[ii].preco);
                        for (
                            var iii = 0;
                            iii < this.carrinho[i].etapas[ii].escolha.length;
                            iii++
                        ) {
                            preco3 =
                                preco3 +
                                parseFloat(this.carrinho[i].etapas[ii].preco);
                        }
                    }
                }

                this.precoFinal = preco + preco2 + preco3;
                this.precoTotalItem.push({
                    itemId: this.carrinho[i].id,
                    precoFinal: this.precoFinal,
                });
            }
        },

        /** volta para a tela de escolha de itens */
        voltar() {
            this.$router.back(); 
        },

        /**
         * retorna data formatada para verificar se a promocao ainda esta valida
         * @returns {string} data formatada "2024-12-12 06:35:00"
         */
         getFormattedDate() {
            const now = new Date();

            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },

        /**
         * vai montar o pedido em formato json e sera usado na funcao finalizarPedido.
         *
         * Traz do store os dados de numeroEmpresaFK, idgarcom, codigoCentroResultado, itensCarrinho e numero da mesa
         * @see {@link this.finalizarPedido()}
         */
        montarJsonDoPedido() {
            let tipo = this.$store.state.porCartao ? "cartao" : "mesa"
            let json = new PedidoModel();
            
            json.tipo = tipo;
            json.mesa = this.$store.state.mesa;
            json.cartao = this.numeroComanda;
            json.valorTotalCarrinho = this.getFinalTotal;
            json.cnpj = this.$store.state.cnpj;
            json.numeroEmpresaFK = this.$store.state.numeroEmpresaFK;
            json.IdGarcons_FK = this.$store.state.idgarcom;
            json.idTerminal = this.$store.state.idTerminal;
            json.numeroComanda = this.numeroComanda;
            json.CodigoCentroResultado_FK = this.$store.state.codigoCentroResultado;
            json.itens = this.$store.state.itensCarrinho;
            json.valorTotalCarrinho = this.calcularValorTotalFinal();

            json.horarioCompra = this.getFormattedDate() // para ver se a promocao ainda vaale. Formato: "2024-12-12 06:35:00"
            
            return json;
        },

        /** caso o a empresa trabalhe por comanda, pedir o numero da comanda */
        async onClickConfirmar() {
            const temAlergia = await this.perguntarAlergia()
            console.log(temAlergia.isConfirmed )
            
            // caso nao tenha alergia, ou nao seja hard rock, seguir com o pedido normalmente
            if (temAlergia.isConfirmed == false ) {
                console.log("proseguir de boa")

                let porCartao = this.$store.state.porCartao;
                if (this.$store.state.isDelivery) {
                    
                    /** vamos montar o pedido e salvar no state */
                    const json = this.montarJsonDoPedido();
                    window.localStorage.setItem("pedido", JSON.stringify(json))
                    this.$store.dispatch("setDeliveryPedido", json)
                    this.$router.push({
                        path: '/delivery'
                    });
                } else {
                    if (porCartao) {
                        this.pedirNumeroCartao();
                    } else {
                        this.perguntarSeTemCerteza();
                    }
                }
            } 
            
            // caso tenha alergia, nao deixar prosseguir com o pedido. Ao invez, chamar o garcom
            else {
                console.log("%ccliente com alergia nao ir em frente, chamar garcom!", "color: yellow")
                let json = {
                    cnpj: this.$store.state.cnpj,
                    idTerminal: this.$store.state.idTerminal,
                    operacao: 11,
                    NumeroCMS_FK: this.$store.state.mesa,
                    mesa: this.$store.state.mesa,
                    tipo: "Cliente com alergia",
                    IdGarcons_FK: this.$store.state.idgarcom,
                    CodigoCentroResultado_FK: this.$store.state.codigoCentroResultado,
                    idCaixaLio: this.$store.state.idTerminal,
                    numeroEmpresaFK: this.$store.state.numeroEmpresaFK
                }

                apiChamarGarcom(json).then((res) => {
                    console.log(res)
                    this.loading = false;
                    Swal.fire({
                        title: "Aguarde em sua mesa que um garçom virá até você.",
                        html: '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50.000000pt" height="50.000000pt" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"> <path d="M45 455 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80 -80 80 -19 0 -40 -9 -55 -25z"/> <path d="M370 371 c-5 -11 -19 -23 -30 -26 -20 -6 -41 -38 -54 -82 -7 -23 -7 -23 104 -23 111 0 111 0 104 23 -13 44 -34 76 -54 82 -11 3 -25 15 -30 26 -6 10 -15 19 -20 19 -5 0 -14 -9 -20 -19z"/> <path d="M7 293 c-4 -3 -7 -66 -7 -140 l0 -133 110 0 110 0 0 62 0 61 23 -21 c13 -12 28 -22 34 -22 6 0 31 24 56 53 27 32 59 58 79 64 30 10 27 11 -37 12 -45 1 -76 -4 -84 -11 -10 -11 -16 -8 -32 12 -48 62 -58 70 -88 70 -29 0 -30 -2 -48 -80 -10 -44 -20 -80 -23 -80 -3 0 -13 36 -23 80 -16 71 -21 80 -41 80 -12 0 -26 -3 -29 -7z"/> </g> </svg>'
                    })
                })
            }
            
        },

        /**
         * Case HardRock, vamos pergunar se o cliente tem alergia e retornar true caso sim.
         */
        async perguntarAlergia() {
            if (this.$store.state.isHardRock == true) {
                return await Swal.fire({
                    title: "Você tem alguma alergia conhecida?",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não tenho alergia",
                    showCancelButton: true,
                    cancelButtonColor: "green",    
                    allowOutsideClick: false,            
                })
            } else {
                return {isConfirmed: false, isDenied: true, isDismissed: false, value: false};
            }
        },
 
        /** vamos setar habilitarQrcodeScanner para true, mostrando assim a camera para escanear o barcode */
        pedirNumeroCartao() {
            scrollTo(0,0)
            this.habilitarQrcodeScanner = true
        },

        /** vamos confirmar o numero da comanda, e apenas caso o usuario confirme, enviar o pedido */
        onBarcodeLido(val) {
            this.habilitarQrcodeScanner = false
            this.numeroComanda = val
            controleConfirmarNumeroCartao(val)
                .then((res) => {
                    if (res == true) {
                        this.finalizarPedido(val)
                    }
                })
                .catch((err) => {
                    console.error("Failed to confirm " + err)
                    this.habilitarQrcodeScanner = true
                    this.numeroComanda = null
                })
        },


        /** mostra um alert perguntando se o usuario tem certeza, e depois da resposta, enviar o pedido */
        perguntarSeTemCerteza() {
            Swal.fire({
                cancelButtonText: "CANCELAR", 
                showCancelButton: true,
                showConfirmButton: true,
                title: "Deseja confirmar o pedido?",
                confirmButtonText: "SIM",
                reverseButtons: true,
                buttonsStyling: {width: "50%"}
            }).then((res) => {
                if (res.isConfirmed) {
                    this.finalizarPedido(null);
                    // this.finalizarPedido2();
                }
            });
        },

        /**
         * usado apenas para testes
         */
        finalizarPedido22() {
            console.log(this.montarJsonDoPedido())
            CONSOLOGAR[(this.montarJsonDoPedido())];
            CONSOLOGAR[(JSON.stringify(this.montarJsonDoPedido()))];
        },


        /**
         * vai enviar para a api todo o pedido do carrinho
         * ao trazer a resposta, vai chamar a funcao {@link finalizarDepoisDaRespostaChegar()}
         */
        finalizarPedido(numero_cartao) {
            this.loading = true;
            /** 
             * temos que criar essa var self para ser o contexto. La no SWAL.fire, o metodo didClose inicia outro
             * contexto, entao ele nao funcioava usar this.loading = false. Agora, passando a var SELF.loading
             * funciona, pq estamos passando o contexto para frente
             */
            const self = this;

            apiSincronizacao( 
                this.$store.state.cnpj,
                this.$store.state.idTerminal
            ).then((res) => {
                console.log(res, self, numero_cartao)
                if (res) {

                    /** ver se a mesa nao esta em conta antes de enviar o pedido */
                    apiBuscarStatusMesa(this.$store.state.cnpj, this.$store.state.idTerminal, this.$store.state.numeroEmpresaFK, numero_cartao).then((res) => {
                        this.$store.dispatch("setStatusEConta", {statusMesa: res.status, totalContaMesa: res.valor_total});

                        /** se a mesa estiver em conta, exibir erro */
                        if (res.status.toString().toLowerCase() === "conta") {
                            Swal.fire({
                                title: "A conta já foi fechada anteriormente. Não é possível lançar mais itens.",
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: "OK",
                            });
                            this.loading = false;
                        } else if (res.status.toString().toLowerCase() === "bloqueado") {
                            Swal.fire({
                                title: "Seu cartão está bloqueado. Não é possível lançar mais itens.",
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: "OK",
                            });
                            this.loading = false;
                        } else if (res.status.toString().toLowerCase() === "perdido") {
                            Swal.fire({
                                title: "Esse cartão não pode ser utilizado. Não é possível lançar itens.",
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: "OK",
                            });
                            this.loading = false;

                        /** caso contrario, enviar o pedido */
                        } else {
                            const options = {headers: { "Content-Type": "application/json" }};
                            let body = this.montarJsonDoPedido()
                            CONSOLOGAR(["body do pedido", JSON.stringify(body)])

                            axios.post(
                                    rotaDeProducao() + "/venda",
                                    body,
                                    options
                                ).then((response) => {
                                    CONSOLOGAR([response.data]);
                                    this.finalizarDepoisDaRespostaChegar();
                                }).catch((error) => {
                                    console.log(`%c taaqui mano ${error}`, "color: yellow")
                                    console.log(error.response)
                                    console.log(Object.keys(error));
                                    CONSOLOGAR([error])

                                    if (error.response.status == 599) { // erro da promocao
                                        Swal.fire({
                                            title: "PROMOCAO",
                                            text: error.response.data.message,
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            cancelButtonText: "OK",
                                            didClose: () => {
                                                self.loading = false;
                                            }
                                        });
                                    } else {
                                        Swal.fire({
                                            title: "Algo deu errado. Por favor, chame um atendente e informe este problema.",
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            cancelButtonText: "OK",
                                            didClose: () => {
                                                self.loading = false;
                                            }
                                        });
                                    }
                                    // this.loading = false;
   
                                });
                        }
                    }).catch((err) => {
                        CONSOLOGAR([err])
                        this.loading = false;
                        Swal.fire({
                            title: "Algo deu errado. Por favor, chame um atendente e informe este problema.",
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: "OK",
                        });
                    });
                } else {
                    this.loading = false;
                    Swal.fire({
                        title: "Não há conexão com o servidor. Por favor, chame um atendente e informe este problema.",
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: "OK",
                    });
                }
            }).catch((e) => {
                console.log("erro de sinc")
                console.log(e)
                Swal.fire({
                    title: "Não há conexão com o servidor. Por favor, chame um atendente e informe este problema.",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "OK",
                });
                this.loading = false;
            });
        },

        /**
         * So inicia quando vem a resposta do backend.
         * vai limpar o cache e dar um alert mostrando que o pedido foi feito.
         * @see finalizarPedido
         */
        finalizarDepoisDaRespostaChegar() {
            window.localStorage.clear(); // limpar os dados com cache
            this.$store.commit("SET_LIMPAR_ITENS_CARRINHO");
            // this.$store.commit("SET_LIMPAR_TUDO");

            let dadosUrl = {
                cnpj: this.$store.state.cnpj,
                idTerminal: this.$store.state.idTerminal,
                isMenuEstatico: this.$store.state.isMenuEstatico,
                isDelivery: this.$store.state.isDelivery,
            };

            CONSOLOGAR(["dados de url", dadosUrl])

            this.loading = false;

            Swal.fire({
                    title: '<p style="color: #77a; font-family: Arial, Helvetica">Pedido feito com sucesso!<p>',
                    showConfirmButton: true,
                }).then(() => {
                    // this.voltar();
                });

                // vamos buscar todos os itens de novo
            this.$store.dispatch("darRefresh", dadosUrl).then(() => {});
        },

        /** anima o botao quando o usuario tenta enviar pedido mas o botao esta desabilitado */
        funcaoAnimarTextoDesabilidado() {
            this.animarTextoDesabilidado = true;
            setTimeout(() => {
                this.animarTextoDesabilidado = false;
            }, 1000);
        },
    },
};
</script>

<style scoped>
.loading {
    margin-top: 120px;
    margin-inline: auto;
}

.carrinho-body {
    height: 100vh;
    overflow-y: hidden;
    padding: 0px;  
    margin: 0;
    animation: carrinho-anim 0.5s forwards;
}
@keyframes carrinho-anim {
    0%  { opacity: 0; scale: 0.9; }
    100%  { opacity: 1; scale: 1;}
}
.carrinho-body .titulo {color: var(--cor1); font-size: 20px; font-weight: bold; margin-block: 10px;}

.bods {
    background: white;
}

.background-brancos {
    border: solid 10px var(--primaryDark_azul);
}

.top-bar {
    background: white;
    margin-top: 5px;
}

.nome-item {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.container-cards {
    padding-inline: 5px;
    background: white;
    overflow-y: auto;
    flex: 1;
}


.precoTotal > div {
    animation: precoTotalAnim 1s forwards;
}

@keyframes precoTotalAnim {
    0% {
        opacity: 0;
        background: green;
    }
    100% {
        opacity: 1;
    }
}




h4 {
    color: black;
    text-align: left;
    margin-inline: 0;
}


.texto-desabilitado-anim {
    animation: animTextoDesab 0.3s linear forwards;
}
@keyframes animTextoDesab {
    0% {
        transform: scaleX(80%);
    }
    100% {
        transform: scaleX(100%);
    }
}
</style>

